import React, { useCallback } from "react"
import { connect } from "react-redux"
import { Navigate } from "react-router-dom"
import { updateSession, clearSession } from "../../Redux/sessionSlice"

import EdianLogo from '../../assets/edian.png'
import AwsLogo from '../../assets/aws.png'
import OllamaLogo from '../../assets/ollama.webp'
import EsLogo from '../../assets/es.png'
import ReactLogo from '../../assets/react.png'
import PythonLogo from '../../assets/python.webp'
import GolangLogo from '../../assets/golang.png'
import GitlabLogo from '../../assets/gitlab.png'
import AvinLogo from '../../assets/avin.jpg'
import FlutterLogo from '../../assets/flutter.jpg'

import "./LandingPage.css";
import { avatarClasses } from "@mui/material"


class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  scrollTo(elementId) {
    const el = document.getElementById(elementId);
    console.log(`--scroll to ${elementId}`)
    console.log(el)
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="Page LandingPage">

        <ul className='NavigationBar'>
          <li><img src={EdianLogo} /></li>
          <li className='NavigationBarGrowItem'>Edian Software</li>
          <li onClick={e => this.scrollTo('testimonials')}>Testimonials</li>
        </ul>

        <div className="PageBlock">
          <p>
            Edian Software is a technology consultancy operating out of South Africa
          </p>

          <p>that has been building and maintaining various software systems since 2013</p>

          <p>using world class methodologies, infrastructure and technologies</p>

          <p className='PageTechnologies'>
            <img src="https://edian.io/assets/aws_cpf.png" />
            <img src="https://edian.io/assets/aws_aif.png" />
            <img src="https://edian.io/assets/aws_saa.png" />
            <img src="https://edian.io/assets/aws_da.png" />
            <img src="https://edian.io/assets/aws_mla.png" />
            <img src="https://edian.io/assets/aws_sysaa.png" />
            <img src="https://edian.io/assets/aws_data_associate.png" />
            <img src="https://edian.io/assets/aws_sap.png" />
            <img src="https://edian.io/assets/aws_dep.png" />
            <img src="https://edian.io/assets/aws_mls.png" />
            <img src="https://edian.io/assets/aws_ss.png" />
            <img src="https://edian.io/assets/aws_das.webp" />
            <img src="https://edian.io/assets/aws_database.webp" />
            <img src="https://edian.io/assets/aws_nws.png" />

            <img src="https://edian.io/assets/hashicorp_terraform.png" />
          </p>

          <p>
            lately, we have been exploring how these technologies can benefit small business
          </p>
        </div>

        <div className="PageBlock">
          <h1>Project TalentFit - Recruitment Industry</h1>
          <p>A new, more cost effective take on supporting recruitment efforts that incorporates artificial intelligence and integrates into modern platforms.</p>
          <p className='PageTechnologies'>
            <img src={GitlabLogo} />
            <img src={AwsLogo} />
            <img src={FlutterLogo} />
            <img src={GolangLogo} />
            <img src={PythonLogo} />
            <img src={ReactLogo} />
            <img src={EsLogo} />
            <img src={OllamaLogo} />
          </p>
        </div>

        <div className="PageBlock">
          <h1>Project Orbiter - Aerospace Industry</h1>
          <p>Manages the reservation of satellite overpasses and related workflows.</p>
          <p className='PageTechnologies'>
            <img src={GitlabLogo} />
            <img src={AwsLogo} />
            <img src={PythonLogo} />
            <img src={ReactLogo} />
          </p>
        </div>

        <div className="PageBlock" id='testimonials'>
          <div className='PageReference'>

            <img src={AvinLogo} />
            <p>
              "Edian's expertise, dedication, and creativity has significantly contributed to the
              success of our recent projects. Their ability to navigate complex challenges and deliver high-quality
              solutions consistently has been appreciated by our team" Avin Yadav, Director MFT Advisory Services
            </p>
          </div>

        </div>

        <div className="PageBlock">
          <p>
            if you are curious to find out more, we are eager to hear from you .. <a href='mailto:info@edian.io'>info@edian.io</a>
          </p>
          <p>© Edian Software 2025. All rights reserved.</p>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({
  updateSession,
  clearSession,
});

export default connect(mapStateToProps, mapDispatchToProps())(LandingPage);
