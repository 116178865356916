import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LandingPage from "./Pages/LandingPage/LandingPage";


import "./App.css";

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    var self = this;
  }

  render() {
    let session = this.props.session;

    return (
      <div className="App">
        <Routes>
          <Route index element={<LandingPage />} />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps())(App);
